<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>


<template>
    <input
        v-model="proxyChecked"
        type="checkbox"
        :value="value"
        class="rounded dark:bg-neutral-900 border-neutral-300 dark:border-neutral-700 text-verde-600 shadow-sm focus:ring-verde-500 dark:focus:ring-verde-600 dark:focus:ring-offset-neutral-800"
    >
</template>
